@import "../../variables";

.about-container {
    background: $main-dark;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;

}

.about-content {
    z-index: 3;
    min-height: 15rem;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    background: none;
}

.about-section {
    min-height: 20rem;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $text1;
    background: none;;

}


.about-text {
    z-index: 3;
    width: 48rem;
    position: relative;
    flex-direction: column;
    padding: .5rem 1.5rem;
    display: block;
    align-items: center;
    text-align: left;
    background: none;

}

.absolute {
    position: absolute !important; 
}

.about-container h1 {
    color: $text1;
    background: $main-dark;
    font-size: 3rem;
    margin:0;
    text-align: left;
}

.about-underline {
    padding: 0 0 3px 0;
    background: $underline;
    margin-bottom: 1.5rem;
}

.about-container p {
    margin-bottom: 1.5rem;
    color: $text1;
    font-size: 1.25rem;
    text-align: left;
    background: none;
}


.about-art {
    margin: 1.5rem;
    width: 22.5rem;
    min-height: 230px;
    z-index: 10;
    box-shadow: $main-dark 10px 10px 0px -3px, $accent 10px 10px;
   }

.AboutLink:hover:after {
    width: 100%;
  }

@media screen and (max-width: 1280px) {
    .about-container {
        min-height: 720px;
    }
    .about-content {
        flex-direction: column !important;
    }

    .about-text {
        width: 90%;
        padding: 0;
    }

}



@media screen and (max-width: 768px) {


    .about-art {
    width: 320px;
    }
}
