@import "../../variables";

.skills-containter {
    background: linear-gradient($main-dark 33%, $main 33%);;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0px;
    position: relative;
    z-index: 1;
}


.skills-block {
    min-height:35rem;
    width:25rem;
    background: $main-light;
    margin: 2rem;
    padding: 2rem;
    border: 1px solid $main-dark;
    box-shadow: 0 2px 4px -1px rgba(0,0,0,0.3), 0 4px 5px 0 rgba(0,0,0,0.2), 0 1px 10px 0 rgba(0,0,0,0.1);
    display: flex;
    color: $text1;
    flex-direction: column;
    text-align:center;
    border-radius: .625rem;
}

.skills-containter h1{
    color: $text1;
    font-size: 1.25rem;
    text-align: left;
    background: $main-light;
    width: 100%;
}

.skills-underline {
    padding: 0 0 3px 0;
    width: 80%;
    background: $underline;
    margin-top: 2rem;
}

.skills-containter p{
    margin-top: 1rem;
    color: $text1;
    text-align: center;
}

.skills-list{
    text-align: left;
    list-style: none;
    margin: 1rem;
}

.skills-containter  {
    display: flex;
    align-items: center;
}

.skills-list li {
    margin-top: 1.25rem;
}


.skills-icon{
    font-weight:900;
    font-size: x-large;
    width: 1.5rem;
    height: 1.5rem;

    margin-right: 1rem;
}


@media screen and (max-width: 960px) {

    .skills-containter {
        flex-direction: column !important;
    }

    .skills-block {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }


}
