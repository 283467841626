@import "../../variables";

.hero-container {
    background: url("../../Photos/hero\ section3.png");
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 48rem;
    z-index: 1;

    box-shadow: 0 2px 4px -1px rgba(0,0,0,0.3),0 4px 5px 0 rgba(0,0,0,0.2),0 1px 10px 0 rgba(0,0,0,0.1);

}


.hero-content {
    padding: .5rem 1.5rem;
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
    text-shadow: 1px 3px 11px rgba(0,0,0,.3);
}


.hero-container h1 {
    color: $text1;
    font-size: 10vw;
    margin: 0px;
}

.hero-container p {
    margin-top: 1.5rem;
    color: $text1;
    font-size: 1.5rem;
    text-align: center;

}


.hero-link {
    display: flex;
    height: 2.5rem;
    width: 9rem;
    border-radius: 1rem;
    -webkit-appearance: none; 
    -moz-appearance: none; 
    appearance: none;
    background-clip: padding-box;
    box-shadow: none !important;
    border:none;
    outline:none;
    padding: .5rem;
    box-sizing: border-box;
    font-size:1rem;
    margin-top: 1.5rem;
    background: $accent;
    color: $text2;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.hero-link:hover {
    background: $accent-dark;
}

@media screen and (max-width: 1280px) {

    .hero-container {
        flex-direction: column-reverse;
    }

    .hero-content {
        justify-content: center;
        align-items: center;
        margin:0;
    }

}

@media screen and (max-width: 768px) {

    .hero-container {
        background-size: 160%;
    }
    
    .hero-container p {
        font-size: 1.25rem;
    }
}

@media screen and (max-width: 480px) {

    .hero-container h1 {
        font-size: 2.75rem;
    }
}