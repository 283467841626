@import "../../variables";


.projects-container {
    background: $main;
    display: flex;
    flex-direction: column;
    padding: 6rem 0;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;

}

.projects-title {
    color: $text1;
    background: $main;
    font-size: 3rem;
    margin:0;
    text-align: left;
}

.projects-underline {
    padding: 0 0 3px 0;
    background: $underline;
    margin-top: 1.5rem;
    margin-bottom: 3rem;
}

.projects-project {
    z-index: 2;
    position: relative;
    width: 50rem;
    overflow: hidden;
    box-shadow: 0 2px 4px -1px rgba(0,0,0,0.3), 0 4px 5px 0 rgba(0,0,0,0.2), 0 1px 10px 0 rgba(0,0,0,0.1);
    border-radius: .25rem;
    display: flex;
    flex-direction: column;
    padding: .4rem 2rem;
    margin: 1rem;
    background: $main-light;
}

.projects-project h2 {
    color: white;
    margin: .5rem;
    font-weight:400 !important;
}

.projects-content {
    z-index: 1;
    color: white;
    height: 0;


    transition: all .5s cubic-bezier(.67,.07,.3,.96);
    -webkit-transition: all .5s cubic-bezier(.67,.07,.3,.96);
    -o-transition: all .5s cubic-bezier(.67,.07,.3,.96);
    -moz-transition: all .5s cubic-bezier(.67,.07,.3,.96);
}


.projects-project.active > .projects-content{
    height: 15rem;
}


.projects-content p {
    color: white;
    margin: .5rem;
    font-size: 1.1rem;
}

.projects-content span {
    display: block;
    height: auto;
}

.projects-content span a {
    color: lightgray;
    text-decoration: none;
    margin-right: 1rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: .5rem;
}

.projects-content span a .link-icon{
    color: lightgray;
    text-decoration: none;
    margin-right: .4rem;
    font-size: larger;
    font-weight: bolder;
}



.projects-languages {
    display: block;
    position:relative
}

.projects-language-node {
    display: inline-block;
    background: darken($accent, 10%);
    color: gray;
    border-radius: .5rem;
    padding: .5rem;
    font-size: .9rem !important;
}

.project-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    color: white;
    align-items: baseline;
    font-size: 1.1rem;
}

.project-title > p{
    text-align: right;
}

.projects-dropdown {
    display: flex;
    flex-direction: row;
    align-items: center;

}




.projects-arrow {
    right:0;
    width: 24px;
    height: 24px;
    margin:.5rem;
    margin-left: 2rem;
    display: flex;
    align-items: center;
    box-sizing: border-box;
}


.projects-arrow::before,
.projects-arrow::after {
  content: '';
  position: absolute;
  width: 14px;
  height: 4px;
  background: #fff;
  box-shadow: 0 2px 5px rgba(255,101,47,.2);

  transition: all .5s cubic-bezier(.67,.07,.3,.96);
  -webkit-transition: all .5s cubic-bezier(.67,.07,.3,.96);
  -o-transition: all .5s cubic-bezier(.67,.07,.3,.96);
  -moz-transition: all .5s cubic-bezier(.67,.07,.3,.96);
}

.projects-arrow::before {
    transform: translateX(0) rotate(45deg);
}

.projects-arrow::after {
    transform: translateX(8px) rotate(-45deg);
}

.projects-project.active > .projects-dropdown .projects-arrow::before {
    transform: translateX(8px) rotate(45deg);

}

.projects-project.active > .projects-dropdown >.projects-arrow::after {
    transform: translateX(0px) rotate(-45deg);

}

@media screen and (max-width: 960px) {

    .projects-project {
        width: 90%;
    }
}

@media screen and (max-width: 720px) {

    .project-title > p{
        display: none;
    }

    .ProjectImage {
        height: 100%
    }

    .projects-project:hover > .ProjectImage{
        height: 110%;
    }

    .projects-project.active > .projects-content{
        height: 20rem;
    }

    
}

@media screen and (max-width: 540px) {
    .projects-project.active > .projects-content{
        height: 32rem;
    }

}