@import "../../variables";

.sidebar-container {
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: #191919;
    display: grid;
    align-items: center;
    top: 0;
}

.sidebar-wrapper {
    color: $text1;
}

.sidebar-items {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 80px);
    text-align: center;

}

.sidebar-link {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2 ease-in-out;
    color: $text1;
    cursor: pointer;
    
}
.sidebar-link:hover {
    color: $accent;
    transition: 0.2s ease-in-out;
}

.closed {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    right: -100%;
}

.open {
    opacity: 1;
    right: 0;
    display: grid;
    transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 480) {

    .sidebar-items {
        grid-template-rows: repeat(6, 60px);
    }
}