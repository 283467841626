@import "./variables";

* {
  box-sizing: border-box;
  margin:0;
  padding:0;
  font-family: 'Encode Sans Expanded', sans-serif;
}

body {
  background: $main;
}


@media only screen and (min-width: 1800px) {
    
  * {
      font-size: 24px;
  }
}

.bg-main {
  background: $main;
}

.bg-main-light {
  background: $main-light;
}
.bg-main-dark {
  background: $main-dark;
}

.bg-accent {
  background: $accent;
}

.bg-accent-dark {
  background: $accent-dark;
}

.bg-underline {
  background: $underline
}

.text-text1 {
  color: $text1;
}

.text-text2 {
  color: $text2;
}