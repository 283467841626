@import "../../variables";

nav {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: absolute;
  padding: .25rem 10rem;
  z-index: 10;
  width: 100%;
  top: 1rem;

  @media screen and (max-width: 960px) {
      transition: 0.8s all ease;
  }

}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.NavH1 {
  background: $accent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  padding: 0 .5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  text-decoration: none;
}

.navbar-mobile-button {
  z-index: 9999;
  display:none;

}

.navbar-menu {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  list-style: none;
  text-align: left;

  box-sizing: border-box;

}



.navbar-item {
    display: flex;
    align-items: center;
    text-align: none;
    text-decoration: none;
    margin: .25rem;
    cursor: pointer;
}

@media screen and (max-width:1280px) {
  nav {
    padding: .25rem 2rem;
  }
}

@media screen and (max-width:768px) {

    nav {
      padding: .5rem 2rem !important;
    }
    .navbar-container {
      justify-content: flex-end;
    }

    .navbar-mobile-button {
        display: flex;
        align-items: center;
        list-style: none;
        text-align: left;
        color: $text1;
        font-size: 1.8rem;
        margin-bottom: 2px;
    }
    .navbar-menu {
        display: none;
    }
  }

.navbar-link {
  height: 2rem;
  width:100%;
  display: flex;
  padding: .5rem;
  align-items: center;
  color: $text1;
  border-radius: .325rem;
  position: relative;

  text-shadow: 1px 3px 11px rgba(0,0,0,.3);
  

  cursor: pointer;
}

.navbar-link:after {
  content: '';
  height: .5rem;
  background-color: $accent;
  position: absolute;
  opacity: 0;
  bottom: -1rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: .5rem;
  border-radius: 50%;

  transition: all .2s ease-in-out;
}

.navbar-link:hover:after,
.navbar-link.active:after {
  opacity: 1;
  bottom: -.5rem;
}

.hamburger-menu {
  display: flex;
  height: 48px;
  width: 48px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.hamburger-btn > .hamburger-middle  {
  stroke-dasharray: 60 60;

  transition: all .5s ease-in-out;
}

.hamburger-btn.active > .hamburger-middle  {

  stroke-dasharray: 0 60;
  stroke-dashoffset: -30;

  transition: all .5s ease-in-out;
}

.hamburger-btn > .hamburger-curves  {
  stroke-dasharray: 60 1000;

  transition: all .5s ease-in-out;
}

.hamburger-btn.active > .hamburger-curves {
  stroke-dasharray: 88 1000;
  stroke-dashoffset: -144.5;

  transition: all .5s ease-in-out;
}