@import "../../variables";

.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: sticky;
  padding: .5rem 10rem;
  top: 0;
  z-index: 10;

  background: $main-dark;
  color: $text1;
  font-size:1rem;

}

.footer-menu {
  width: 10rem;
 display: flex;
 justify-content: space-evenly;
 flex-direction: row;
}

.footer-button {
  height: 2rem;
  width:2rem;
}

.footer-menu a {
  text-decoration: none;
  color: $text1;
  font-size: xx-large;
  margin: .5rem;

}

.footer-menu a:hover {
  color: darken($text1, 40%)
}

